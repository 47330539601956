<template>
<v-container fluid class="pa-0">
  <v-parallax
    height="400"
    dark
    src="/imgs/dmDanceBG.jpg"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        class="text-center"
        cols="12"
      >
        <h1 class="text-h4 font-weight-black mb-4">
          <strong>Contact Us</strong>
        </h1>
        <h4 class="subheading">
          Feel free to use any of the contact options below!
        </h4>
      </v-col>
    </v-row>
  </v-parallax>
  <v-row class="pt-15 mt-10">
    <v-col col="8" class="pl-10">
      <v-card outlined class="mx-auto pl-5 pt-5 pr-5 pb-5">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="sendEmail"
      >
        <v-text-field solo class="ma-2" :disabled="formBusy" label="Your Name" name="user_name"></v-text-field>
        <v-text-field solo class=" ma-2" :disabled="formBusy" label="E-mail" name="user_email"></v-text-field>
        <v-textarea solo label="Your Message" name="message"></v-textarea>
              <v-progress-linear v-show="formBusy" :indeterminate="true"></v-progress-linear>
              <div class="g-recaptcha pb-5" data-sitekey="6LcN_V8eAAAAALV8qcZcW1M2eeBS8mjmEklYpiUC"></div>
              <v-btn class="white--text" color="primary" @click="submitEmail">
                <v-icon>Submit</v-icon>
              </v-btn>
      </v-form>
      </v-card>

    </v-col>
    <v-col col="2" class="pa-10">
      <v-card
        class="mx-auto"
        max-width="400"
      >
    
        <v-img
          class="white--text align-end bg-gradientClothing"
          height="200px"
          src="/imgs/dmDanceBG.jpg"
        >
          <v-card-title class="text-h4">Linda vd Walt</v-card-title>
        </v-img>

        <v-card-subtitle class="pb-0 font-weight-black text-h4 mb-4">
          Contact Details
        </v-card-subtitle>

        <v-card-text class="text--primary">
          <div class="mb-4"><strong>Email:</strong> <a href="mailto:dmpotch@dmdanceco.com">dmpotch@dmdanceco.com</a></div>
          <div class="mb-4"><strong>Number:</strong> +27 (82) 782-6837</div>
        </v-card-text>
      </v-card>
  </v-col>
  <v-col col="2" class="pa-10">
      <v-card
        class="mx-auto"
        max-width="400"
      >
    
        <v-img
          class="white--text align-end bg-gradientClothing"
          height="200px"
          src="/imgs/dmDanceBG.jpg"
        >
          <v-card-title class="text-h4">Mona Beukes</v-card-title>
        </v-img>

        <v-card-subtitle class="pb-0 font-weight-black text-h4 mb-4">
          Contact Details
        </v-card-subtitle>

        <v-card-text class="text--primary">
          <div class="mb-4"><strong>Email:</strong> <a href="mailto:dmpotch@dmdanceco.com">dmpotch@dmdanceco.com</a></div>
          <div class="mb-4"><strong>Number:</strong> +27 (84) 666 8482</div>
        </v-card-text>
      </v-card>
  </v-col>
  </v-row>
  </v-container>
</template>

<script>
  import emailjs from '@emailjs/browser';

export default {
    

    computed: {
      
    },

    methods: {
    sendEmail() {
      emailjs.sendForm('service_3pq874q', 'template_c483f5v', this.$refs.form, 'user_vwRui5SPEkCsa5W4VIafk')
        .then((result) => {
            console.log('SUCCESS!', result.text);
        }, (error) => {
            console.log('FAILED...', error.text);
        });
    }
  }
  
}
</script>
<style>

.view-enter-active {
  animation-duration: 1.5s;
  animation-name: slideIn;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-delay: 1s ease-in;
}

@keyframes slideIn {
  from {
    opacity: 0;
    margin-left:-100%;
  }
  
  to {
    opacity: 1;
    margin-left:0%;
  }
}

.bg-grey {
  background-color: rgba(0, 0, 0, 0.05);
}

.v-card.on-hover.theme--dark {
  transition: background-color 0.5s ease;
  background-color: rgba(#FFF, 0.8)
}
.bg-gradientClothing {
      background-image: linear-gradient(
  135deg
  ,#f342e4 25%,#ffb7ff 67%)!important;
}
</style>